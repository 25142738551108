import { GetStaticProps } from 'next';
import { composeProps } from 'next-compose-props';
import { WebPageJsonLd } from 'next-seo';
import { useRouter } from 'next/router';

import type { MarketingPageProps, NextPageWithLayout } from '@monorepo/types';

import { getWebpage } from '@cms/store';
import { Page } from '@common/components';
import { wrapper } from '@common/store';
import { getTranslations, ogImageBuilder } from '@common/utils';
import { revalidateTime } from '@common/utils/cache/cache';
import { WebLayout } from '@marketing/layouts';

const HomePage: NextPageWithLayout<MarketingPageProps<unknown>> = ({ page }) => {
	const { pathname } = useRouter();
	return (
		<Page.CMSV2
			path='home'
			title={page?.title}
			openGraph={ogImageBuilder()}
			page={page}
			description={page?.description}>
			<WebPageJsonLd
				id={`${process.env.NEXT_PUBLIC_WEBAPP_URL}${pathname ? `${pathname}` : ''}`}
				datePublished={page?.publish_date}
				reviewedBy={page?.author}
				lastReviewed={page?.publish_date}
				description={page?.description}
			/>
		</Page.CMSV2>
	);
};

export const getStaticProps = wrapper.getStaticProps((store) => {
	// @ts-ignore: Ignore typescript warning
	return composeProps(getTranslations, async () => {
		const cmsContent = await store.dispatch(getWebpage.initiate({ path: `home` }));

		if (!cmsContent.data?.response || cmsContent.error) {
			return {
				notFound: true,
			};
		}
		return {
			props: {
				page: cmsContent.data.response,
			},
			revalidate: revalidateTime('hour'),
		};
	}) as GetStaticProps;
});

HomePage.getLayout = (page) => {
	return <WebLayout>{page}</WebLayout>;
};

export default HomePage;
